import Client from './client'

const BASE_URL = 'v1/consumer/policies/:policyUUID/content'


export default async function getPolicyContent({ locale, policyUUID }) {
  const searchParams = new URLSearchParams()
  searchParams.append('lang', locale)

  const baseURL = BASE_URL.replace(':policyUUID', policyUUID)

  const url = `${ baseURL }?${ searchParams.toString() }`

  const { data } = await Client.get(url)

  return data.content
}
